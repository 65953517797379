// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// types
import { chat } from './chat';
// import { showSuccessToast } from 'utils/showSuccessToast';
import { CampaignData, CampaingsStateProps, ChannelContact } from 'types/campaings';
import { showSuccessToast } from 'utils/showSuccessToast';

export const url = process.env.REACT_APP_API_URL + '/api/marketing/v1';

const initialState: CampaingsStateProps = {
  campaigns: [],
  metaCampaings: {
    pages: 0,
    rows: 0
  },
  campaignsTypes: [],
  totalCampaigns: [],
  totalCampaignsSends: { campaigns: [], cost: 0 },
  totalMonthlySends: { campaigns: [], cost: 0 },
  totalMonthlyContact: [],
  totalActiveContact: [],
  totalCampaignsFiltred: [],
  totalCampaignsSendsFiltred: { campaigns: [], cost: 0 },
  totalActiveContactsFiltred: { total: 0 }
};

export const campaigns = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    setCampaigns(state, action) {
      state.campaigns = action.payload;
    },
    setMetaCampaings(state, action) {
      state.metaCampaings = action.payload;
    },
    setCampaignsTypes(state, action) {
      state.campaignsTypes = action.payload;
    },
    setTotalCampaigns(state, action) {
      state.totalCampaigns = action.payload;
    },
    setTotalCampaignsSends(state, action) {
      state.totalCampaignsSends = action.payload;
    },
    setTotalMonthlySends(state, action) {
      const newCampaigns = action.payload.campaigns;

      const existingYears = state.totalMonthlySends.campaigns.map((campaign) => campaign.monthYear.split('-')[0]);

      const filteredCampaigns = newCampaigns.filter(
        (campaign: { monthYear: string }) => !existingYears.includes(campaign.monthYear.split('-')[0])
      );

      state.totalMonthlySends = {
        ...state.totalMonthlySends,
        campaigns: [...state.totalMonthlySends.campaigns, ...filteredCampaigns]
      };
    },
    setTotalMonthlyContact(state, action) {
      const newData = action.payload;

      const existingMonths = new Map(state.totalMonthlyContact.map((data) => [data.monthYear, data]));

      newData.forEach((newEntry: { monthYear: string; channels: ChannelContact[] }) => {
        if (existingMonths.has(newEntry.monthYear)) {
          const existingEntry = existingMonths.get(newEntry.monthYear);
          if (existingEntry) {
            const mergedChannels = [
              ...existingEntry.channels,
              ...newEntry.channels.filter(
                (newChannel) => !existingEntry.channels.some((existingChannel) => existingChannel.id === newChannel.id)
              )
            ];
            existingEntry.channels = mergedChannels;
          }
        } else {
          existingMonths.set(newEntry.monthYear, newEntry);
        }
      });

      state.totalMonthlyContact = Array.from(existingMonths.values());
    },
    setTotalActiveContact(state, action) {
      state.totalActiveContact = action.payload;
    },
    setTotalCampaignsFiltred(state, action) {
      state.totalCampaignsFiltred = action.payload;
    },
    setTotalCampaignsSendsFiltred(state, action) {
      state.totalCampaignsSendsFiltred = action.payload;
    },
    setTotalActiveContactsFiltred(state, action) {
      state.totalActiveContactsFiltred = action.payload;
    }
  }
});

export function getCampaigns(filter?: string, page = 1, orderField = 'dateFrom', types?: string) {
  return async () => {
    try {
      const response = await axios.get(`${url}/campaigns`, {
        params: {
          filter,
          page,
          order_field: orderField,
          types
        }
      });

      dispatch(campaigns.actions.setCampaigns(response.data.data));
      dispatch(campaigns.actions.setMetaCampaings(response.data.meta));

      return;
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getCampaignsById(campaignId: string) {
  return async () => {
    try {
      const response = await axios.get(`${url}/campaigns/${campaignId}`);
      return response.data;
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getCampaignsTypes() {
  return async () => {
    try {
      const response = await axios.get(`${url}/campaign-types`);

      return dispatch(campaigns.actions.setCampaignsTypes(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function addCampaigns(campaign: CampaignData) {
  return async () => {
    try {
      await axios.post(`${url}/campaigns`, {
        channels: campaign.channels,
        content: campaign.content || null,
        dateFrom: campaign.dateFrom,
        description: campaign.description || null,
        groups: campaign.groups || null,
        mediaType: campaign.mediaType || null,
        name: campaign.name,
        types: campaign.types
      });
      showSuccessToast('Campanha adicionada com sucesso');
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function editCampaigns(campaign: CampaignData) {
  return async () => {
    try {
      await axios.put(`${url}/campaigns/${campaign._id}`, {
        channels: campaign.channels,
        content: campaign.content || null,
        dateFrom: campaign.dateFrom,
        description: campaign.description || null,
        groups: campaign.groups || null,
        mediaType: campaign.mediaType || null,
        name: campaign.name,
        types: campaign.types
      });
      showSuccessToast('Campanha editada com sucesso');
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function removeCampaign(campaignId: string) {
  return async () => {
    try {
      const response = await axios.delete(`${url}/campaigns/${campaignId}`);

      return dispatch(campaigns.actions.setCampaignsTypes(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getTotalCampaigns() {
  return async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/report-marketing/v1/total-campaigns`);

      return dispatch(campaigns.actions.setTotalCampaigns(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getTotalCampaignsSends() {
  return async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/report-marketing/v1/total-campaign-sends`);

      return dispatch(campaigns.actions.setTotalCampaignsSends(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getTotalMonthlySends(selectedYear: number) {
  return async (dispatch: any, getState: any) => {
    const { totalMonthlySends } = getState().campaigns;

    const yearAlreadyLoaded = totalMonthlySends.campaigns.some((campaign: any) =>
      campaign.monthYear.startsWith(`${selectedYear}-`)
    );

    if (yearAlreadyLoaded) {
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/report-marketing/v1/total-monthly-channel-campaign-sends?year=${selectedYear}`
      );

      dispatch(campaigns.actions.setTotalMonthlySends(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getTotalMonthlyContact(selectedYear: number) {
  return async (dispatch: any, getState: any) => {
    try {
      const state = getState();
      const existingData = state.campaigns.totalMonthlyContact;

      const hasDataForYear = existingData?.some((data: { monthYear: string }) =>
        data.monthYear.startsWith(`${selectedYear}-`)
      );

      if (hasDataForYear) {
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/report-contact/v1/total-monthly-active-contact-channels?year=${selectedYear}`
      );

      dispatch(campaigns.actions.setTotalMonthlyContact(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getTotalActiveContact() {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/report-contact/v1/total-active-contact-channels`
      );

      return dispatch(campaigns.actions.setTotalActiveContact(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getTotalCampaignsFiltred(date: string) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/report-marketing/v1/total-campaigns?dateFrom=${date}T00:00:00&dateTo=${date}T23:59:59`
      );

      return dispatch(campaigns.actions.setTotalCampaignsFiltred(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getTotalCampaignsSendsFiltred(date: string) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/report-marketing/v1/total-campaign-sends?dateFrom=${date}T00:00:00&dateTo=${date}T23:59:59`
      );

      return dispatch(campaigns.actions.setTotalCampaignsSendsFiltred(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getTotalActiveContactsFiltred(date: string) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/report-contact/v1/total-active-contacts?dateFrom=${date}T00:00:00&dateTo=${date}T23:59:59`
      );

      return dispatch(campaigns.actions.setTotalActiveContactsFiltred(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export default campaigns.reducer;
export const { setCampaigns } = campaigns.actions;
