import { Box, Button, Dialog, DialogActions, DialogContentText, Grid } from '@mui/material';

interface ConfirmationModalProps {
  open: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  onClickSecondaryButton?: () => void;
  title: any;
  description: any;
  firstDescription?: any;
  closeMessage?: string;
  confirmMesssage?: string;
  midButtonMessage?: string;
  fullWidth?: boolean;
}

const ConfirmModal = ({
  open,
  onClose,
  onConfirm,
  onClickSecondaryButton,
  title,
  description,
  firstDescription,
  closeMessage,
  confirmMesssage,
  midButtonMessage,
  fullWidth
}: ConfirmationModalProps) => {
  return (
    <Dialog
      fullWidth={fullWidth}
      open={open}
      onClose={onClose}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& .MuiDialog-container': {
          alignItems: 'start'
        }
      }}
    >
      <Grid
        p={3}
        pb={0}
        height={'100% !important'}
        minWidth={'300px'}
      >
        <Box>{title}</Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column  ' }}>
          <DialogContentText
            mb={1}
            style={{ opacity: 0.7 }}
          >
            {firstDescription}
          </DialogContentText>
          <Box textAlign={'center'}>{description}</Box>
        </Box>
        <DialogActions>
          {closeMessage?.length ? (
            <Button
              onClick={onClose}
              color="primary"
            >
              {closeMessage}
            </Button>
          ) : null}
          {midButtonMessage?.length ? (
            <Button
              onClick={onClickSecondaryButton}
              color="secondary"
            >
              {midButtonMessage}
            </Button>
          ) : null}
          {confirmMesssage?.length ? (
            <Button
              onClick={onConfirm}
              color="secondary"
            >
              {confirmMesssage}
            </Button>
          ) : null}
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default ConfirmModal;
